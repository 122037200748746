// This event listener handles the form submission in Heyflow
// It extracts the user's email and first name (if available) and sends this data to edgetag
// It also triggers a 'Lead' tag when a submission occurs
window.addEventListener('heyflow-submit', (e) => {
  const elementsObj = e.detail.fieldsSimple;
  if (!elementsObj) {
    return;
  }
  const user = {};
  const email = elementsObj['Email address'];
  if (email) {
    user.email = email;
  }
  const firstName = elementsObj['First name'];
  if (firstName) {
    user.firstName = firstName;
  }
  if (Object.keys(user) == 0) {
    return;
  }
  edgetag('data', user, {},
      {
        method: 'beacon',
      }
);
  edgetag('tag', 'Lead', {}, {},
      {
        method: 'beacon',
      }
);
});
// This event listener triggers the PageView tag for every new screen in the Heyflow
window.addEventListener('heyflow-screen-view', (e) => {
  edgetag('tag', 'PageView');
});
// This event listener triggers specific tags based on custom button click events in the Heyflow
// It listens for AddToCart and Start_quiz events
window.addEventListener('heyflow-button-click', (e) => {
  // Extract the custom event name from the event details
  const { customEventName } = e.detail;
  
  // Handle AddToCart event
  if (customEventName === 'AddToCart') {
    // Trigger the AddToCart tag with specific product details
    edgetag('tag', 'AddToCart', {
      currency: 'GBP',
      value: 59.95,
      contents: [{
        id: '8302020559036',
        variantId: '43624661647548',
        quantity: 1,
        item_price: 59.95,
        title: '50% Deposit - Your Custom Signet Card',
        image: 'https://cdn.shopify.com/s/files/1/0590/1529/9260/files/blacksteelshiney_36213004-7455-4617-9413-eb4e01294a72.png',
        url: 'https://signetlondon.com/products/50-deposit-your-custom-signet-card'
      }],
      checkoutUrl: 'https://signetlondon.com/cart/43624661647548:1'
      },
    {},
    { method: 'beacon' },
);
  }
  // Handle Start_quiz event
  if (customEventName === 'Start_quiz') {
    edgetag('tag', 'Start_quiz');
  }
});

// This event listener handles input click events, specifically for CustomizeProduct
window.addEventListener('heyflow-input-click', (e) => {
  const { customEventName } = e.detail;
  
  if (customEventName === 'CustomizeProduct') {
    edgetag('tag', 'CustomizeProduct');
  }
});